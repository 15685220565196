import React from 'react';

import Layout from '../../components/layout';
import './index.sass';
import SEO from '../../components/seo';

const Index = () => {
  return (
    <Layout pageType="blog">
      <SEO
        keywords={['terms']}
        title="Node Vision | Employee Directory - Terms of Use"
      />
      <div className="ed-terms">
        <section className="heading">
          <h1>Terms of Use</h1>
        </section>
        <section className="content">
          <div className="container">
            <div className="p1">Employee Directory – Terms of Use</div>
            <div>
              <strong>Terms &amp; Conditions</strong>{' '}
              <p>
                By installing or using the app, these terms will automatically
                apply to you – you should make sure therefore that you read them
                carefully before using the app. You’re not allowed to copy, or
                modify the app, any part of the app, or our trademarks in any
                way. You’re not allowed to attempt to extract the source code of
                the app, and you also shouldn’t try to translate the app into
                other languages, or make derivative versions. The app itself,
                and all the trade marks, copyright, database rights and other
                intellectual property rights related to it, still belong to NODE
                VISION PTY LTD.
              </p>{' '}
              <p>
                NODE VISION PTY LTD is committed to ensuring that the app is as
                useful and efficient as possible. For that reason, we reserve
                the right to make changes to the app or to charge for its
                services, at any time and for any reason. We will never charge
                you for the app or its services without making it very clear to
                you exactly what you’re paying for.
              </p>{' '}
              <p>
                You should be aware that there are certain things that NODE
                VISION PTY LTD will not take responsibility for. Certain
                functions of the app will require the app to have an active
                internet connection. The connection can be Wi-Fi, or provided by
                your mobile network provider, but NODE VISION PTY LTD cannot
                take responsibility for the app not working at full
                functionality if you don’t have access to Wi-Fi, and you don’t
                have any of your data allowance left.
              </p>{' '}
              <p />{' '}
              <p>
                If you’re using the app outside of an area with Wi-Fi, you
                should remember that your terms of the agreement with your
                mobile network provider will still apply. As a result, you may
                be charged by your mobile provider for the cost of data for the
                duration of the connection while accessing the app, or other
                third party charges. In using the app, you’re accepting
                responsibility for any such charges, including roaming data
                charges if you use the app outside of your home territory (i.e.
                region or country) without turning off data roaming. If you are
                not the bill payer for the device on which you’re using the app,
                please be aware that we assume that you have received permission
                from the bill payer for using the app.
              </p>{' '}
              <p>
                With respect to NODE VISION PTY LTD’s responsibility for your
                use of the app, when you’re using the app, it’s important to
                bear in mind that although we endeavour to ensure that it is
                updated and correct at all times, we do rely on third parties to
                provide information to us so that we can make it available to
                you. NODE VISION PTY LTD accepts no liability for any loss,
                direct or indirect, you experience as a result of relying wholly
                on this functionality of the app.
              </p>{' '}
              <p>
                NODE VISION PTY LTD does not promise that it will always update
                the app so that it is relevant to you and/or works with your
                SharePoint version.
              </p>{' '}
              <p>
                <strong>Changes to This Terms and Conditions</strong>
              </p>{' '}
              <p>
                We may update our Terms and Conditions from time to time. Thus,
                you are advised to review this page periodically for any
                changes. We will notify you of any changes by posting the new
                Terms and Conditions on this page.
              </p>{' '}
              <p>These terms and conditions are effective as of 2020-07-23</p>{' '}
              <p>
                <strong>Contact Us</strong>
              </p>{' '}
              <p>
                If you have any questions or suggestions about our Terms and
                Conditions, do not hesitate to contact us at{' '}
                <a href="mailto:info@nodevision.com.au" className="link">
                  info@nodevision.com.au
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Index;
